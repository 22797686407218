import 'lazysizes'
import { smoothScroll } from './animation/smooth-scroll'
import { fullscreenNav } from './fullscreen-nav'
import { pageBackground } from './modules/page-background'

/**
 * Functionality here will be executed on all pages
 */

export const global = (): void => {
  const modals = Array.from(document.querySelectorAll('[data-modal]')) as HTMLElement[]
  const portal = document.querySelector('#portal') as HTMLElement

  modals.forEach(lightbox => {
    portal.appendChild(lightbox)
  })

  smoothScroll()
  fullscreenNav()
  pageBackground()

  // Check for anchor ID in URL and scroll to it on page load
  window.addEventListener('load', () => {
    const hash = window.location.hash
    if (hash) {
      const targetElement = document.querySelector(hash)
      if (targetElement) {
        setTimeout(() => {
          // Assuming Lenis is initialized and available globally
          if (window.lenis) {
            window.lenis.scrollTo(targetElement, {
              offset: -150, // Adjust this value as needed for your layout
              duration: 1.5,
              easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)) // Ease out expo
            })
          } else {
            console.warn('Lenis is not initialized. Falling back to default scroll behavior.')
            targetElement.scrollIntoView({ behavior: 'smooth' })
          }
        }, 300) // 300ms delay before scrolling
      }
    }
  })
}
